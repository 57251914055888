// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hzs5YJZDfpafdnWgP7No {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 36px;
    box-sizing: border-box;

    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.TfHUXek6Tc0RPIcF7Fdq {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-blur-wrapper);
    backdrop-filter: blur(3px);

}

/* for css transition animations */
.m0fS841Q5cSABfPTreYB {
    opacity: 0;
    transform: scale(0.9);
}

.pynN5l3VoLSwLqw3lwIw {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.RotkDjlwDRNjCGESA30w {
    opacity: 1;
}

.zPBEVCAheQoLM4kJO06s {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

/* for css transition animations */`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalCoockieAgreement/modalCoockie.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;;IAET,aAAa;IACb,sBAAsB;;IAEtB,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;IAC3C,0BAA0B;;AAE9B;;AAEA,kCAAkC;AAClC;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,0CAA0C;AAC9C;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,0CAA0C;AAC9C;;AAEA,kCAAkC","sourcesContent":[".container {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n\r\n    padding: 36px;\r\n    box-sizing: border-box;\r\n\r\n    z-index: 999;\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.overlay {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: var(--modal-blur-wrapper);\r\n    backdrop-filter: blur(3px);\r\n\r\n}\r\n\r\n/* for css transition animations */\r\n.alert-enter {\r\n    opacity: 0;\r\n    transform: scale(0.9);\r\n}\r\n\r\n.alert-enter-active {\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n    transition: opacity 300ms, transform 300ms;\r\n}\r\n\r\n.alert-exit {\r\n    opacity: 1;\r\n}\r\n\r\n.alert-exit-active {\r\n    opacity: 0;\r\n    transform: scale(0.9);\r\n    transition: opacity 300ms, transform 300ms;\r\n}\r\n\r\n/* for css transition animations */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hzs5YJZDfpafdnWgP7No`,
	"overlay": `TfHUXek6Tc0RPIcF7Fdq`,
	"alert-enter": `m0fS841Q5cSABfPTreYB`,
	"alert-enter-active": `pynN5l3VoLSwLqw3lwIw`,
	"alert-exit": `RotkDjlwDRNjCGESA30w`,
	"alert-exit-active": `zPBEVCAheQoLM4kJO06s`
};
export default ___CSS_LOADER_EXPORT___;
