var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { useMediaQuery } from "react-responsive";
import style from "./cropper.module.css";
function ImageCropper(_a) {
    var image = _a.image, onCropDone = _a.onCropDone, onCropCancel = _a.onCropCancel;
    var isMobile = useMediaQuery({
        query: "(max-width: 770px)",
    });
    var _b = useState({ x: 0, y: 0 }), crop = _b[0], setCrop = _b[1];
    var _c = useState(1), zoom = _c[0], setZoom = _c[1];
    var _d = useState(null), croppedArea = _d[0], setCroppedArea = _d[1];
    var _e = useState(3 / 1), aspectRatio = _e[0], setAspectRatio = _e[1];
    var onCropComplete = function (croppedAreaPercentage, croppedAreaPixels) {
        setCroppedArea(croppedAreaPixels);
    };
    var onAspectRatioChange = function (event) {
        setAspectRatio(event.target.value);
    };
    var styleDesktop = {
        containerStyle: {
            width: "100%",
            height: "80%",
            backgroundColor: "#fff",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
        },
    };
    var styleMobile = {
        containerStyle: {
            width: "100%",
            height: "70%",
            backgroundColor: "#fff",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
        },
    };
    return (_jsxs("div", __assign({ className: style.cropper }, { children: [_jsx(Cropper, { image: image, aspect: aspectRatio, crop: crop, zoom: zoom, onCropChange: setCrop, onZoomChange: setZoom, onCropComplete: onCropComplete, style: isMobile ? styleMobile : styleDesktop }), _jsx("div", __assign({ className: style.actionButtons }, { children: _jsxs("div", __assign({ className: style.buttonWrapper }, { children: [_jsx("button", __assign({ className: style.cancelButton, onClick: onCropCancel }, { children: "\u041D\u0430\u0437\u0430\u0434" })), _jsx("button", __assign({ className: style.saveButton, onClick: function () {
                                onCropDone(croppedArea);
                            } }, { children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" }))] })) }))] })));
}
export default ImageCropper;
